<template>
  <ion-page>
    <ion-content>
      <div class="main">
        <div class="topBackground">
          <ion-text class="regular cWhite ion-text-center">
            <h1 class="ion-no-margin menuTitleFont">Mon Planning</h1>
          </ion-text>
        </div>
        <ion-text v-if="slots.length > 0">
          <h3 class="message">OMG ! Tu vas kiffer tes prochaines missions 😍</h3>
        </ion-text>
        <div class="maxWidth">
          <div v-if="slots.length > 0">
            <div v-for="slot in slots" :key="slot.missionId">
              <ConfirmedMission
                  :mission-id="slot.missionId"
                  :lng="slot.longitude"
                  :lat="slot.latitude"
                  :pharmacyName="slot.pharmacyName"
                  :workedHours="slot.workedHours"
                  :increase="slot.increase"
                  :day="slot.day"
                  :date-start="slot.beginTime"
                  :date-end="slot.endTime"
                  :salary="slot.salary"
                  :title="slot.title"
                  :rest="slot.pauseHours"
                  :address="slot.address"
                  :distance="slot.distance"
                  :vip-type="slot.vipType"
                  :is-flexible="slot.isFlexible"
                  :can-display-map="canDisplayMap"
              />
            </div>
          </div>
          <div v-else>
            <ion-text color="primary">
              <h3 class="ion-margin-horizontal">Tu n'as aucune mission. Ça ne saurait tarder 🚀</h3>
              <ion-button v-on:click="seeMissions">
                Go 🚀 Des missions !
              </ion-button>
            </ion-text>
          </div>
        </div>
      </div>
      <div v-if="!isDesktop" class="footerBackground">
        <img class="ion-margin-bottom logoFooter" src="../../public/assets/icon/logo-alloo-pharma.svg" >
      </div>
      <div v-else class="footerNotMobile">
        <img class="ion-margin-bottom logoFooter" src="../../public/assets/icon/alloopharma-logo.png" >
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import router from '@/router'
import moment from 'moment'
import store from '@/store'
import { IonPage, IonContent, IonText, IonButton} from '@ionic/vue'
import {getShifts} from "@/services/api";
import ConfirmedMission from "@/components/ConfirmedMission";
import {Loader} from "@googlemaps/js-api-loader";


export default  {
  name: 'Planning',
  components: {ConfirmedMission, IonPage, IonText, IonContent, IonButton},
  data() {
    return {
      canDisplayMap: false,
    }
  },
  computed: {
    isDesktop() {
      return store.getters.isDesktop
    },
    slots() {
      const slots = store.getters.slotsNextConfirmed
      slots.forEach(slot => {
        const endDate = moment(slot.day, "YYYY-MM-DD").locale('fr').format('D MMM YYYY')
        const beginTime = slot.beginTime.split("T")[1].substring(0, 5)
        const endTime = slot.endTime.split("T")[1].substring(0, 5)
        slot.dateTimeMissionConfirmed = {
          date: endDate,
          timeTable: `${beginTime} -> ${endTime}`
        }
      });
      return slots
    }
  },
  methods: {
    seeMissions(){
      router.push("/missions")
    },
  },

  ionViewWillEnter() {
    getShifts();
    const loader = new Loader({ apiKey: 'AIzaSyA8qMxbDPD837BKlodEN1mIIQPpfPG8hqw' })
      loader.load().then(() => {
        this.canDisplayMap = true;
      });
  },

}
</script>
<style scoped>

h1 {
  padding:50px;
  margin-top: -20px
}
.message{
  margin-bottom: 50px;
  color: var(--ion-color-primary);
}
.headerCard {
  background-color: #d56498;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 0 !important;
  color: rgb(255, 247, 247);
}


</style>
