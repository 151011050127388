<template>
  <div id="container">
    <ion-card class="fullCard">
      <ion-card-header>
        <ion-card-title>
          <div class="ion-margin-bottom black">
            <h2 class="dateConfirmed">{{ date }}</h2>
            <div class="padding-left">
              <h3 class="ion-text-left ">
                🕒 {{ timeTable }}
              </h3>
              <h3 class="ion-text-left" v-if="rest">☕ {{ rest }}h</h3>
            </div>
          </div>
          <h1 class="headerCard ">
            <span class="address">
              {{ address || "Adresse non renseignée" }}
            </span>
          </h1>
        </ion-card-title>
      </ion-card-header>
      <div v-if="lat && lng && canDisplayMap">
        <Map :lng="lng" :lat="lat" :markers="getMarkers()" />
      </div>
      <ion-card-content class="mainCard">
        <div v-if="pharmacyName" class="ion-text-center">
          <ion-text class="pharmacy">{{ pharmacyName }}</ion-text>
        </div>

        <div class="flex-container space-around distanceGain">
          <ion-item lines="none border">
            <ion-icon class="icon" :icon="navigateOutline"></ion-icon>
            <ion-text>
              <h3>
                {{ distance }}km
              </h3>
            </ion-text>
          </ion-item>
          <ion-item lines="none" class="border">
            <ion-text color="success">
              <h2>
                {{ cSalary }}€
              </h2>
            </ion-text>
          </ion-item>
        </div>
        <ion-button class="ion-item-center ion-margin-bottom" v-on:click="showMission">
          Voir la mission
        </ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</template>
<script>
import store from "@/store";
import {
  pin,
  medkitOutline,
  documentText,
  logoEuro,
  informationCircleOutline,
  calendarOutline,
  navigateOutline
} from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonCard,
  IonItem,
  IonText,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from "@ionic/vue";
import Map from "@/components/GoogleMap.vue";
import router from "@/router";
import moment from "moment";
import {toRefs} from "vue";

export default {
  name: "ConfirmedMission",
  components: {
    Map,
    IonButton,
    IonIcon,
    IonCard,
    IonItem,
    IonText,
    IonCardContent,
    IonCardHeader,
    IonCardTitle
  },
  data() {
    return {
      userLngLat: store.getters.lngLat
    };
  },

  computed: {
    cSalary() {
      return this.salary + this.workedHours * this.increase;
    },
    phoneAlloopharma() {
      return `tel:${store.getters.phoneAlloopharma}`;
    },
    formatedDate() {
      const { dateStart, dateEnd } = this;
      if (dateStart === 'Invalid date' || dateEnd === 'Invalid date') {
        return 'Reste à définir';
      }
      return `${dateStart} - ${dateEnd}`;
    }
  },
  setup(props) {
    const { day, dateStart, dateEnd, isFlexible } = toRefs(props);
    const date = moment(day.value, "YYYY-MM-DD").locale('fr').format('D MMM YYYY');
    const beginTime = dateStart.value.split("T")[1].substring(0, 5);
    const endTime = dateEnd.value.split("T")[1].substring(0, 5);
    const timeTable = `${beginTime} à ${endTime} ${isFlexible ? '(flexible)' : ''}`;
    return {
      pin,
      navigateOutline,
      medkitOutline,
      documentText,
      logoEuro,
      informationCircleOutline,
      calendarOutline,
      timeTable,
      date,
    };
  },
  props: {
    lng: { type: Number, required: false, default: null },
    lat: { type: Number, required: false, default: null },
    distance: { type: Number, required: true },
    address: { type: String, required: true },
    vipType: { type: Boolean, required: true },
    isFlexible: { type: Boolean, required: true },
    pharmacyName: { type: String, required: false, default: null },
    increase: { type: Number, required: false, default: null },
    day: { type: String, required: false, default: null },
    dateStart: { type: String, required: false, default: null },
    dateEnd: { type: String, required: false, default: null },
    salary: { type: Number, required: false, default: null },
    workedHours: { type: Number, required: true, default: null},
    title: { type: String, required: true },
    rest: { type: Number, required: false, default: null },
    missionId: { type: Number, required: false, default: null },
    canDisplayMap: { type: Boolean, required: true, default: false },
  },
  watch: {
  },

  methods: {
    showMission() {
      store.dispatch('setCurrentMission', { id: this.missionId });
      router.push("/mission")
    },
    getMarkers() {
      const pharmacyMarker = {
        url: "https://cdn.iconscout.com/icon/free/png-256/pharmacy-3721872-3127406.png",
        title: this.pharmacyName,
        position: { lng: this.lng, lat: this.lat },
      };
      return [store.getters.userMarker, pharmacyMarker];
    }
  }
};
</script>
<style scoped>
#container {
  --ion-grid-columns: 24;
}
.dateTime {
  background-color: var(--ion-color-light);
  color: white;
  padding: 4px;
}
.dateConfirmed {
  margin-bottom: 14px;
  text-decoration: underline;
}
.gain {
  font-size: 30px;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #2dd36f;
}
.flex-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.center {
  justify-content: center;
}
.marginR {
  margin-right: 25px;
}
.marginL {
  margin-left: 25px;
}
.border {
  box-shadow: aliceblue;
}
.alignItem {
  display: inline-block;
  vertical-align: middle;
}
.mainCard {
  padding: 8px;
  padding-bottom: 0px;
}
.marginCard {
  padding: 5px;
  margin: 8px;
  border-radius: 15px;
}
.iconInKnowMore {
  margin-right: 12px;
}
.space-around {
  justify-content: space-around;
}
.icon {
  color: #d56498;
}
.icons {
  margin-bottom: 0px;
  background-color: aliceblue;
  background-size: auto;
}
.dateMissionPossible {
  font-size: 18px !important;
}
ion-icon {
  font-size: 30px;
}
ion-row {
  align-items: center;
}
.pharmacy {
  color: var(--ion-color-primary);
  font-size: 21px;
}
h1 {
  color: rgb(109, 109, 109);
  font-size: 18px;
  font-family: "SFProTextBold";
  margin-top: 0px;
}
h2 {
  font-size: 25px;
}
h3 {
  color: grey;
  font-size: 20px;
  margin: 5px;
}
h5 {
  margin: 5px;
  color: var(--ion-color-secondary);
}
ion-card-title {
  border-bottom: solid;
  border-color: grey;
}

.headerCard {
  background-color: #d56498;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 0 !important;
  color: rgb(255, 247, 247);
}

ion-card-header {
  padding: 0px;
  padding-inline: 0px;
}

.black {
  color: black;
}

.fullCard {
  padding-bottom: 0px;
  border-radius: 35px;

  margin-bottom: 30px;
}
.description {
  font-size: 14px;
  color: var(--ion-color-secondary);
}
.address {
  width: 97%;
}
.padding-left {
  padding-left: 1rem;
}
ion-card-content {
  padding: 0px !important;
}
</style>
