<template>
    <div style="width: 100%; ">
        <div ref="mapDiv" style="margin: auto; width: 100%; height: 300px" />
    </div>
</template>

<script>
    import { Loader } from '@googlemaps/js-api-loader'
    import { ref, onMounted, toRefs  } from 'vue'

    const KEY = 'AIzaSyA8qMxbDPD837BKlodEN1mIIQPpfPG8hqw';

    export default ({
        name: 'GoogleMap',
        components: {},
        props: {
            lat: {type: Number, required: true},
            lng: {type: Number, require: true},
            markers: {type: Array, require: false},
        },
        setup(props) {
            const loader = new Loader({ apiKey: KEY })
            const { lat, lng, markers } = toRefs(props);
            const mapDiv = ref(null);

            onMounted(async () => {
                await loader.load();
                // eslint-disable-next-line no-undef
                const map = new google.maps.Map(mapDiv.value, {
                    center:  { lat: lat.value, lng: lng.value },
                    zoom: 12,
                    disableDefaultUI: true,
                    mapId: '301fae7e73c874ae',
                    mapTypeId: "roadmap",
                });
                markers.value.forEach((marker) => {
                    // eslint-disable-next-line no-undef
                    new google.maps.Marker({
                        ...marker,
                      icon: {
                          url: marker.url,
                          // eslint-disable-next-line no-undef
                          scaledSize: new google.maps.Size(40, 40), // size
                          // eslint-disable-next-line no-undef
                          origin: new google.maps.Point(0,0), // origin
                      },
                        map: map,
                    });
                })

            });
            return { mapDiv }
        },
    })
</script>
